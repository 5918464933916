import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import jsPDF from 'jspdf';
//import autoTable from 'jspdf-autotable';
import './App.css'; // Import the CSS file

const App = () => {
  const [promptType, setPromptType] = useState('generic');
  const [isRecording, setIsRecording] = useState(false);
  const [stopping, setStopping] = useState(false);
  const [transcript, setTranscript] = useState('');
  const [interimTranscript, setInterimTranscript] = useState('');
  const [dictationCompleted, setDictationCompleted] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [processedText, setProcessedText] = useState('');
  const [downloadableContent, setDownloadableContent] = useState('');

  const recognitionRef = useRef(null);

  const handleRecognitionError = (event) => {
    console.error('Recognition error:', event.error);
    setIsRecording(false); // Stop recording on error
    setStopping(false); // Reset stopping state
    // Provide a user-friendly error message
    setProcessedText(`Error during dictation: ${event.error}. Please try again.`);
  };

  // Function to toggle recording state
  const toggleRecording = () => {
    if (isRecording) {
      setStopping(true); // Indicate that stopping is in progress
      stopDictation();
    } else {
      startDictation();
    }
  };

  const startDictation = () => {
    const SpeechRecognition = window.webkitSpeechRecognition || window.SpeechRecognition;
    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognitionRef.current = recognition;
      recognition.continuous = true;
      recognition.interimResults = true;
  
      recognition.onstart = () => {
        resetTranscription();
        setIsRecording(true); // Set isRecording to true when recognition starts
        console.log('Recognition started');
      };
  
      recognition.onerror = handleRecognitionError;
  
      recognition.onresult = (event) => {
        let interim = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          const transcriptPart = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            console.log('Transcript part:', transcriptPart); 
            setTranscript(prev => {
              //console.log('Previous transcript:', prev); // Log the previous transcript
              return prev + transcriptPart;
            });
          } else {
            interim += transcriptPart;
          }
        }
        setInterimTranscript(interim);
        //console.log('Interim transcript:', interim); // log interim transcripts
      };
  
      recognition.start();
    } else {
      console.error('Speech recognition not available');
      // Update UI to inform the user that speech recognition is not available
      setProcessedText('Speech recognition is not available in your browser.');
    }
  };
  
  const stopDictation = () => {
    if (recognitionRef.current) {
      // Stop the speech recognition service
      recognitionRef.current.stop();

      // Log that we have requested the service to stop
      console.log('Requesting recognition service to stop...');
  
      recognitionRef.current.onend = () => {
        console.log('Recognition stopped by the user');
        setIsRecording(false); // Update the isRecording state to reflect that recording has stopped
        setStopping(false); // Update the stopping state to reflect that stopping is complete
        setDictationCompleted(true); // Indicate that dictation has completed
        console.log('Dictation completed state set to true');
      };
      recognitionRef.current.onerror = (event) => {
        console.error('Recognition stop error', event.error);
        setIsRecording(false); // Also reset isRecording in case of error
        setStopping(false); // Ensure stopping state is reset even if there's an error
      };
    } else {
      console.log('No recognition service to stop');
    }
  };

  // Function to reset transcription
  const resetTranscription = () => {
    setTranscript('');
    setInterimTranscript('');
    setDictationCompleted(false);
    setProcessedText('');
  };

  // Add this utility function for retrying requests
  const retry = async (fn, retries = 3, err = null) => {
    try {
      return await fn();
    } catch (error) {
      if (retries === 1) throw err || error;
      return await retry(fn, retries - 1, err || error);
    }
  };

  // Function to send text to server for processing
  const sendTextToServer = async (text) => {
    console.log('Starting to process text:', text);
    setProcessing(true);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const endpoint = `${apiBaseUrl}/api/process_text`;

    const requestFn = async () => {
      const response = await axios.post(endpoint, { text, promptType });
      return response;
    };

    try {
      const response = await retry(requestFn);
      console.log('Received response from server:', response.data);
      setProcessedText(response.data.summary);
      setDownloadableContent(response.data.summary);
    } catch (error) {
      console.error('Error processing text:', error);
      setProcessedText(`Error processing text: ${error.message}. Please try again.`);
    } finally {
      setProcessing(false);
    }
  };

  // This function turns line breaks into separate paragraphs
  function formatTextWithParagraphs(text) {
    return text.split('\n').map((paragraph, index) => (
      <p key={index}>{paragraph}</p>
    ));
  }
  
  const handleDownloadTxt = () => {
    // Create a Blob from the data
    const blob = new Blob([downloadableContent], { type: 'text/plain;charset=utf-8' });
  
    // Create a link element, use it to "download" the Blob, and then remove it
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'processed-text.txt';
    link.click();
    window.URL.revokeObjectURL(link.href); // Clean up the URL object
  };

  // Assuming you have a function called `createPDF` triggered by a button click
  const createPDF = (processedText, promptType) => {
    // Create a new instance of jsPDF
    const doc = new jsPDF();

    // Set the font for the entire document
    doc.setFont('helvetica', 'normal');
    //doc.setFont('Roboto', 'sans-serif');

    // Add a title to your report
    doc.setFontSize(22);
    doc.text('ScrAIb ' + promptType.toUpperCase() + ' Note', 
        105, 20, null, null, 'center');

    // Draw a line under the title
    doc.setLineWidth(0.5);
    doc.line(20, 25, 190, 25);

    // Add a subtitle
    doc.setFontSize(16);
    doc.setTextColor(100);
    doc.text('Patient: John Doe', 20, 35);

    // Add some content
    doc.setFontSize(12);
    doc.setTextColor(0);
    doc.text(processedText, 20, 45, {maxWidth: 172})

    // Add a table manually
    /*
    autoTable(doc, {
        startY: 160,
        head: [['ID', 'Name', 'Age', 'Gender']],
        body: [
        ['1', 'John Doe', '35', 'Male'],
        ['2', 'Jane Smith', '29', 'Female'],
        // ... More rows
        ],
    });
    */

    // You could also add images using doc.addImage()

    // Save the PDF
    doc.save('medical-report.pdf');
  };

  const handleDownloadPDF = () => {
    // Call the function with the processed text and prompt type
    createPDF(processedText, promptType);
  };

  useEffect(() => {
    // This effect will call sendTextToServer whenever dictationCompleted is set to true
    if (dictationCompleted && transcript) {
      sendTextToServer(transcript);
    }
  }, [dictationCompleted, transcript]);
  
  useEffect(() => {
    // This effect will react to promptType changes and 
    // will reprocess the text if dictation is completed
    if (dictationCompleted && transcript) {
      console.log('promptType changed, reprocessing text');
      sendTextToServer(transcript);
    }
  }, [promptType]);
  
  return (
    <div className="container">
      <div className="header">
        <div className="logo">ScrAIb</div>
        <h2>Your Personal AI-mediated Medical Scribe</h2>
        <form>
            <div className="radio-container">
                {['generic', 'soap', 'h&p'].map((type) => (
                    <div key={type} className="radio-option">
                    <input
                        id={type}
                        type="radio"
                        value={type}
                        checked={promptType === type}
                        onChange={() => setPromptType(type)}
                    />
                    <label 
                        htmlFor={type} 
                        className={promptType === type ? 'label-checked' : ''}>
                            {type.toUpperCase()} Note
                    </label>
                    </div>
                ))}
            </div>
        </form>
        <button 
            onClick={toggleRecording} 
            disabled={stopping} // Disable the button when stopping is in progress
            className={`button record-button ${isRecording ? 'recording' : ''} ${stopping ? 'disabled' : ''}`}>
                {isRecording ? 'Stop Recording' : 'Start Recording'}
        </button>
        <button 
            onClick={resetTranscription} 
            className="button reset-button">
                Reset
        </button>
      </div>

      <div className="content">
        { (isRecording || transcript) && (
            <div className="text-container">
                <h2>Transcribed Text:</h2>

                {isRecording && !transcript ? (
                    <p className="processing-indicator">Processing...</p>
                ) : (
                    <div className="text-content">
                      <p>{transcript + interimTranscript}</p>
                    </div>
                )}
            </div>
        )}
        { (processing || processedText) && (
            <div className="text-container">
                <h2>Processed Text:</h2>
                {downloadableContent && (
                    <button 
                        onClick={handleDownloadPDF} 
                        className="button download-button">
                            Download
                    </button>
                )}
                <div className="text-content">
                    {processing ? (
                        <p className="processing-indicator">Processing...</p>
                    ) : (
                        formatTextWithParagraphs(processedText)
                    )}
                </div>
            </div>
        )}
      </div>

    </div>
  );
};

export default App;
